import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const chatBoxRef = useRef(null);
  const inputRef = useRef(null);
  const recognitionRef = useRef(null);

  const subtitles = [
    "Hydrate",
    "Better Sleep",
    "More Exercise",
    "Meditate Often",
    "The Perfect Diet",
    "Sharper Focus",
    "Still Smoking?",
    "Build Wealth",
    "Staying Health Conscious",
    "Maximize Happiness",
    "That Self Care",
    "Grow Mindfulness",
    "Enhance Discipline",
    "Keep Self Promises",
    "Beat the Procrastinator",
  ];

  const generateSessionId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const handleSubmit = useCallback(async (textMessage) => {
    if (!textMessage.trim()) return;

    const sessionId = localStorage.getItem('sessionId');
    const newMessages = [...messages, { role: 'user', content: textMessage }];
    setMessages(newMessages);
    setMessage('');
    setIsLoading(true);

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/chat`, {
        messages: [{ role: 'user', content: textMessage }],
        sessionId: sessionId,
      });
      const botMessage = res.data.answer;
      setMessages([...newMessages, { role: 'bot', content: botMessage }]);

      // Use Web Speech API for TTS
      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(botMessage);
        if (selectedVoice) {
          utterance.voice = selectedVoice;
        }
        window.speechSynthesis.speak(utterance);
      } else {
        console.warn('Web Speech API is not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sending message to server:', error);
      setMessages([
        ...newMessages,
        { role: 'bot', content: 'A connection error occurred. Please try again.' },
      ]);
    } finally {
      setIsLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [messages, selectedVoice]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSubtitleIndex((prevIndex) => (prevIndex + 1) % subtitles.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [subtitles.length]);

  useEffect(() => {
    if (!localStorage.getItem('sessionId')) {
      localStorage.setItem('sessionId', generateSessionId());
    }

    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = 'en-US';

      recognition.onresult = (event) => {
        const spokenMessage = event.results[0][0].transcript;
        setMessage(spokenMessage);
        handleSubmit(spokenMessage);
        setIsRecognizing(false);
        document.querySelector('.input-container').classList.remove('activated');
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsRecognizing(false);
        document.querySelector('.input-container').classList.remove('activated');
      };

      recognition.onend = () => {
        setIsRecognizing(false);
        document.querySelector('.input-container').classList.remove('activated');
      };

      recognitionRef.current = recognition;
    } else {
      alert('Sorry, your browser does not support Speech Recognition.');
    }

    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      const defaultVoice = availableVoices.find(voice => voice.name === 'Microsoft Mark - English (United States)');
      setSelectedVoice(defaultVoice || availableVoices[0]); // Fallback to the first voice if the desired one is not found
    };

    window.speechSynthesis.onvoiceschanged = loadVoices;
    loadVoices(); // Initial load
  }, [handleSubmit]);

  const handleSpeechInput = () => {
    if (recognitionRef.current && !isRecognizing) {
      recognitionRef.current.start();
      setIsRecognizing(true);
      document.querySelector('.input-container').classList.add('activated');
    } else {
      document.querySelector('.input-container').classList.remove('activated');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(message);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      const chatBox = chatBoxRef.current;
      const lastMessage = chatBox.lastElementChild;
      if (lastMessage) {
        const scrollOffset = lastMessage.offsetTop - chatBox.clientHeight / 1;
        chatBox.scrollTop = scrollOffset;
      }
    }
  }, [messages]);

  return (
    <div className='App'>
      <div className="logo-container">
        <img src="/logo.png" alt="L.C Green Auto Logo" className="logo" />
      </div>
      <div className="header-container">
        <h1 className="header">Consistency Buddy</h1>
        <h2 className="sub-header">The Key Community</h2>
        <div className="phrases-container">
          
       
        </div>
      </div>
     
      <div className="dynamic-subtitle">
        <p>{subtitles[subtitleIndex]}</p>
      </div>
      <div className="social-icon-container">
    <div className="social-media-icons">
     <a href="https://www.youtube.com/ConsistencyBuddy" target=" _blank" title="YouTube">
                    <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.facebook.com/ConsistencyBuddy" target=" _blank" title="Facebook">
                    <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/consis10cybuddy" target=" _blank" title="Twitter">
                    <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.instagram.com/ConsistencyBuddy" target=" _blank" title="Instagram">
                    <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@ConsistencyBuddy" target=" _blank" title="TikTok">
                    <i className="fab fa-tiktok"></i>
                </a>
                <a href="mailto:info@consistencybuddy.com" title="Email">
                    <i className="fas fa-envelope"></i>
                </a>
        
    
    </div>
</div>

      <div className="chat-container">
        <div id="chat-box" ref={chatBoxRef} className="collapsed">
          {messages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.role === 'user' ? 'user-message' : 'bot-message'}`}>
              {msg.content}
            </div>
          ))}
        </div>
      </div>
      <div className="input-container">
        <button
          id="mic-btn"
          type="button"
          onClick={handleSpeechInput}
        >
          🎤 {isRecognizing && <span>Listening...</span>}
        </button>
        <input
          type="text"
          id="user-input"
          value={message}
          ref={inputRef}
          onChange={(e) => setMessage(e.target.value)}
          onFocus={() => chatBoxRef.current.classList.add('expanded')}
          onBlur={() => {
            // Delay the blur action to allow onMouseDown event on button to prevent default
            setTimeout(() => {
              if (!document.activeElement.isEqualNode(inputRef.current)) {
                chatBoxRef.current.classList.remove('expanded');
              }
            }, 100);
          }}
          onKeyPress={handleKeyPress}
          placeholder="ConsistencyBuddy Ai..."
        />
        <button
          id="send-btn"
          onClick={(e) => { e.preventDefault(); handleSubmit(message); }}
          onMouseDown={(e) => e.preventDefault()}  // Prevent input from losing focus
        >
          Send
        </button>
      </div>

      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default App;
